@import "styles/_colors.scss";

.NavBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  height: 100%;

  &-logo-container {
    width: 64px;
    font-size: 1.5em;
    font-weight: 700;
  }

  &-logo {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  &-nav {
    display: flex;
    align-items: center;
    margin: 0 0 0 12px;

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    &-item {
      margin: 0 12px;
    }

    &-item-link {
      text-decoration: none;
      color: $app-color-font;
    }

    &-item-logo {
      height: 1.5em;
      vertical-align: middle;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .NavBar-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
