@import "styles/_colors.scss";

.Blog {
  &-articles-list {
    list-style: none;
  }

  &-article-link {
    text-decoration: none;
    color: $app-color-font;

    &-date {
      font-size: 0.6em;
      vertical-align: middle;
      margin-left: 12px;
    }
  }
}
