@import "styles/_colors";

.Home {
  &-header {
    display: flex;
    position: relative;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    height: 400px;
  }

  &-header-triangle-1 {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    background-color: $app-color-warn;
    transform: skewY(0deg);
    transition: all 2s ease-out;
    transition-delay: 1s;
    
    &-skew {
      transform: skewY(10deg);
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-section {
    max-width: 100%;
    width: 600px;
    margin: 24px 0;

    &-title {
      color: $app-color-primary;
    }

    &-footer {
      margin: 24px 0 0 0;
    }
  }

  &-journey {
    max-width: 100%;
    width: 800px;

    &-item {
      margin: 24px;
      opacity: 1;
      transition: all 1s ease;
      transform: translateX(0);

      &-hidden {
        visibility: hidden;
        opacity: 0;
        transform: translateX(100px);
      }
    }
  }

  &-inline-img {
    display: inline;
    height: 1em;
    vertical-align: bottom;
  }

  &-to-journey-btn {
    background: none;
    border: none;
    font-size: 2em;
    margin-top: 12px;
    transform: translateY(12px);
    animation: translate-up 1s alternate;
    animation-iteration-count: 5;
  }
}

@keyframes translate-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(12px);
  }
}
