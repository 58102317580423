@import "styles/_colors";

.JourneyItem {
  &-divider {
    margin: 6px 0 48px 0;
  }

  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.5em;

    &-separator {
      margin: 0 12px;
    }

    &-date {
      color: $app-color-accent;
      font-weight: 300;
      margin: 0;
      width: 120px;
    }

    &-logo {
      width: 100px;
    }

    &-title {
      font-weight: 700;
      margin: 0;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-details {
      font-size: 0.6em;
      text-align: left;
      padding-left: 6px;
    }
  }
}
