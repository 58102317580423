@import "styles/_constants.scss";
@import "styles/_colors.scss";

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: $app-color-font;
  max-width: 100vw;
  min-height: 100vh;

  &-header {
    height: $app-header-height;
    min-height: $app-header-height;
  }

  // TODO: refactor into global styles
  a {
    // text-decoration: none;
    color: $app-color-font;

    &:hover {
      color: $app-color-primary;
    }
  }

  &-main {
    margin: 24px 0;
    flex-grow: 1;
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100px;
  }
}
